<template>
  <div class="q-mx-xl">
    <circle-text-page-title
      class="col-12 q-ma-lg"
      tag="h2"
      text-large="text-h2"
      text-small="text-h4"
      :title="t('blogs.title')"
    />
    <div class="items-center justify-center q-col-gutter-lg relative-position">
      <blog-articles-card
        v-for="article in articles"
        :article="article"
        :key="article._key"
      />
    </div>
  </div>
</template>

<script setup>
import { i18n } from "src/boot/i18n";
import BlogArticlesCard from "./BlogArticlesCard.vue";
import CircleTextPageTitle from "src/components/circleText/CircleTextPageTitle.vue";

defineOptions({ name: "BlogArticles" });

const props = defineProps({
  articles: { type: Array, default: () => [] },
});

const { t } = i18n.global;
</script>
