import { sanityClient } from "src/boot/sanity";

export const homeQuery = `*[_id == "home"]{
  _createdAt,
  _id,
  _rev,
  _type,
  _updatedAt,
  body,
  slug,
  seo,
  hero[] {
    _key,
    cta,
    image {
      asset->
    },
    linkInternal {
      reference->
    },
    showCta,
    showTitle,
    title,
    productHotspots[] {
      _key,
      x,
      y,
      productWithVariant {
        product->
      },
      showProduct,
      text,
    },
  },
  modules[]{
    _type,
    _key,
    _type == 'accordion' => {
      groups[]{
        title,
        body[] {
          ...
        }
      }
    },
    _type == 'blogArticle' => {
      title,
      articles[]{
        blog,
        body,
        handle,
        image {
          asset->
        },
        title,
      },
    },
    _type == 'calloutIcons' => {
        iconography[]{
          _type,
          _key,
          body,
          svgPath
        },
        image {
          asset->
        },
        omnisend,
        title,
    },
    _type == 'swiper' => {
      title,
      swiperType,
      swiperItems[] {
        _key,
        cta,
        image {
          asset->
        },
        linkInternal {
          reference->
        },
        showCta,
        showTitle,
        title,
        productHotspots[] {
          _key,
          x,
          y,
          productWithVariant {
            product->
          },
          showProduct,
          text,
        },
      }
    },
    _type == 'zodiac' => {
      title,
      zodiacItems[] {
        _key,
        cta,
        image {
          asset->
        },
        linkInternal {
          reference->
        },
        showCta,
        showTitle,
        title,
        productHotspots[] {
          _key,
          x,
          y,
          productWithVariant {
            product->
          },
          showProduct,
          text,
        },
      }
    }
  },
  showHero,
  title,
}[0]`;

export const queryHomeContent = async () => {
  try {
    const result = await sanityClient.fetch(homeQuery);
    return result;
  } catch (error) {
    console.error("Failed to execute queryHomeContent ", error);
  }
};
