<template>
  <q-page>
    <div
      :class="{ 'absolute-top': $q.screen.gt.sm }"
      class="justify-center row"
      style="margin-top: -16px; z-index: 2"
    >
      <div class="column">
        <circle-text-page-title
          v-if="data?.title"
          tag="h1"
          text-large="text-h1"
          text-small="text-h2"
          :title="data.title"
        />
        <text-portable
          v-if="data?.body"
          animated
          class="text-center"
          style="margin-top: -16px"
          :value="data.body"
        />
      </div>
    </div>
    <div
      v-if="data?.hero"
      :class="swiperHeight"
      class="flex flex-center relative-position"
    >
      <hotspot-swiper
        :data="data.hero"
        eager
        :id="auraId"
        :padding="$q.screen.gt.sm"
        type="coverflow"
        @aura-response="(data) => onAuraResponse(data)"
      />
    </div>
    <div v-if="data.modules.length">
      <div
        class="q-pb-xl relative-position"
        v-for="module in data.modules"
        :key="module._key"
      >
        <component
          :articles="module.articles"
          :is="isComponent(module._type)"
          :callout-icons="module"
          :title="module.title"
          :body="module.body"
          :slides="module.zodiacItems"
          :swiper="module.swiperItems"
          :type="module.swiperType"
        />
      </div>
    </div>
  </q-page>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMeta, useQuasar } from "quasar";
import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import { queryHomeContent } from "src/api/sanity/queries/home";
import { i18n } from "src/boot/i18n";
import { urlFor } from "src/boot/sanityUrlFor";
import { queryClient } from "src/boot/vueQuery";
import { useDehydrateQueryClient } from "src/composables/query/useDehydrateQueryClient";
import { useHydrateQueryClient } from "src/composables/query/useHydrateQueryClient";
import { useQueryErrorHandler } from "src/composables/query/useQueryErrorHandler";
import { useUpdateStoreFromQuery } from "src/composables/query/useUpdateStoreFromQuery";
import { useAuraStore } from "src/stores/aura";
import { useHomeStore } from "src/stores/home";
import BlogArticles from "src/components/blog/BlogArticles.vue";
import CalloutIcons from "src/components/callout/CalloutIcons.vue";
import CircleTextPageTitle from "src/components/circleText/CircleTextPageTitle.vue";
import HotspotModule from "src/components/hotspot/HotspotModule.vue";
import HotspotSwiper from "src/components/hotspot/HotspotSwiper.vue";
import TextPortable from "src/components/text/TextPortable.vue";
import ZodiacSwiper from "src/components/zodiac/ZodiacSwiper.vue";

const QKEY = "IndexPage";

defineOptions({
  name: QKEY,
  async preFetch({ store, ssrContext }) {
    const homeStore = useHomeStore(store);
    try {
      await queryClient.prefetchQuery({
        queryKey: [QKEY],
        queryFn: () => queryHomeContent(),
      });
      homeStore.home = queryClient.getQueryData([QKEY]);
      useDehydrateQueryClient(ssrContext);
    } catch (e) {
      console.error("Failed to execute queryHomeContent", e);
    }
  },
});

const props = defineProps({
  collection: { type: String, default: "new-arrivals" },
  name: { type: String, default: "collection" },
  query: { type: Object, default: () => {} },
  view: { type: String, default: "default" },
});

const $q = useQuasar();
const { t } = i18n.global;
const auraStore = useAuraStore();
const { aura, auraRequest, requestId } = storeToRefs(auraStore);
const homeStore = useHomeStore();
const { home } = storeToRefs(homeStore);
const auraId = ref(QKEY);
const swiperHeightGtSm = "window-height";
const swiperHeightLtMd = "q-mb-sm q-pb-xl";
const swiperHeight = ref(swiperHeightGtSm);

const metaTags = ref({
  description: t("seo.description"),
  ogDescription: t("seo.description"),
  ogImage: t("seo.image"),
  ogTitle: t("seo.title"),
  title: t("seo.title"),
});

const { data, error, isError, isFetching, isLoading, isPending } = useQuery({
  queryKey: [QKEY],
  queryFn: () => queryHomeContent(),
  initialData: home.value,
  placeholderData: keepPreviousData,
});

const isComponent = (type) => {
  switch (type) {
    case "blogArticle":
      return BlogArticles;
    case "calloutIcons":
      return CalloutIcons;
    case "swiper":
      return HotspotModule;
    case "zodiac":
      return ZodiacSwiper;
    default:
      break;
  }
};

const onAuraResponse = (data) => {
  if (data.id !== auraId.value) return;
  aura.value = {
    aura: urlFor(data.slide.image.asset.url).width(100).url(),
  };
};

const requestAura = () => {
  requestId.value = auraId.value;
  auraRequest.value = !auraRequest.value;
};

watch(
  data,
  (newData) => {
    const seoImage = newData?.hero?.[0]?.image?.asset
      ? urlFor(newData.hero[0].image.asset).width(1200).height(630).url()
      : t("seo.image");

    metaTags.value = {
      description: t("seo.description"),
      ogDescription: t("seo.description"),
      ogImage: seoImage,
      ogTitle: t("seo.title"),
      title: t("seo.title"),
    };
  },
  { immediate: true }
);

useMeta(() => ({
  meta: {
    description: {
      name: "description",
      content: metaTags.value.description,
    },
    ogDescription: {
      property: "og:description",
      content: metaTags.value.ogDescription,
    },
    ogImage: {
      property: "og:image",
      content: metaTags.value.ogImage,
    },
    ogTitle: {
      property: "og:title",
      content: metaTags.value.ogTitle,
    },
  },
  title: metaTags.value.title,
}));

onMounted(async () => {
  await nextTick();
  swiperHeight.value = $q.screen.gt.sm ? swiperHeightGtSm : swiperHeightLtMd;
  requestAura();
});

useQueryErrorHandler(error, isError);
useUpdateStoreFromQuery(data, home);
useHydrateQueryClient();
</script>
