<template>
  <div class="relative-position">
    <q-separator />
    <div class="q-py-xl">
      <div v-if="calloutIcons?.image?.asset?.url">
        <image-card
          class="mask-overflow-tb"
          :data="{
            image: urlFor(calloutIcons.image.asset.url)
              .width(responsiveWidth)
              .url(),
          }"
          :ratio="2.1"
        />
      </div>
      <div class="absolute-full bg-primary-fade" style="opacity: 0.6" />
      <div
        class="justify-center q-pb-lg q-mx-auto row"
        ref="motionRef"
        style="max-width: 500px"
      >
        <circle-text-page-title
          class="col-12"
          tag="h2"
          text-large="text-h2"
          text-small="text-h3"
          :title="title"
        />
        <logo-type :color="textColor" style="z-index: 3" />
      </div>
      <div class="relative-position">
        <product-callouts :separator="false" text-class="text-subtitle2" />
      </div>
    </div>
    <q-separator />
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMotion } from "@vueuse/motion";
import { useResponsiveImageWidth } from "src/composables/image/useResponsiveImageWidth";
import { urlFor } from "src/boot/sanityUrlFor";
import { useColorStore } from "src/stores/color";
import { usePreferencesStore } from "src/stores/preferences";
import CircleTextPageTitle from "src/components/circleText/CircleTextPageTitle.vue";
import ImageCard from "src/components/image/ImageCard.vue";
import LogoType from "src/components/logo/LogoType.vue";
import ProductCallouts from "src/components/product/ProductCallouts.vue";

defineOptions({ name: "CalloutIcons" });

const props = defineProps({
  calloutIcons: { type: Object, default: () => {} },
  title: { type: String, default: "Welcome To" },
});

const preferencesStore = usePreferencesStore();
const { reducedMotion } = storeToRefs(preferencesStore);
const responsiveWidth = useResponsiveImageWidth(mdWidth);
const colorStore = useColorStore();
const { textColor } = storeToRefs(colorStore);
const motionRef = ref(null);
const mdWidth = 476 * 2;
let motionInstance = null;
const computedReducedMotion = computed(() => reducedMotion.value);

const createMotionInstance = () => {
  return useMotion(motionRef, {
    initial: {
      y: 100,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 350,
        damping: 20,
        delay: 50,
        onComplete: () => {
          motionInstance.apply("levitate");
        },
      },
    },
    levitate: {
      y: 5,
      transition: {
        duration: 2000,
        repeat: Infinity,
        ease: "easeInOut",
        repeatType: "mirror",
      },
    },
    hover: {
      scale: 1.1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
    leave: {
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
  });
};

const applyAnimations = () => {
  if (!computedReducedMotion.value && motionInstance) {
    motionInstance.apply("enter");
  } else if (motionInstance) {
    motionInstance.stop();
  }
};

watch(computedReducedMotion, () => {
  if (motionInstance) {
    applyAnimations();
  } else if (!computedReducedMotion.value) {
    motionInstance = createMotionInstance();
    applyAnimations();
  }
});

onMounted(async () => {
  await nextTick();
  if (!computedReducedMotion.value) {
    motionInstance = createMotionInstance();
    applyAnimations();
  }
});
</script>
